import React from 'react';
import styles from './Home.module.scss';
import { Image } from 'antd';

export const Home = () => {
  return (
    <div className={styles.homeContainer} id='home'>
      <div className={styles.videoBlock}>
        <video controls={false} src={'./video.mp4'} autoPlay muted loop/>
      </div>
      <div className={styles.gradienBg}></div>
      <div className={styles.homeContent}>
        <div className={styles.titleBlock}>
          <h1>
            Красивые <span>балконы</span> и <span>лоджии</span> в Минске под ключ&nbsp;
          </h1>
          <p>Делаем утепление, остекление, внутреннюю отделку и внешнюю обшивку, электрику и мебель для хранения и уюта на балконе
          — быстро, с гарантией, по доступным ценам&nbsp;
          </p>
          <a href='#feedback'>
              Оставить заявку <Image preview={false} src='./arrow-right.svg'/>
          </a>
        </div>
      </div>
    </div>
  );
};
