import { Image } from 'antd';
import React from 'react';
import styles from './Gallery.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, Autoplay } from 'swiper/modules';
import mainStyles from '../Main/Main.module.scss'


export const Gallery = () => {
    const photos = [
        {
            id: 1,
            photo: './gallery/photo_2024-08-28_19-25-45.jpg'
        },
        {
            id: 2,
            photo: './gallery/photo_2024-08-28_19-25-48.jpg'
        },
        {
            id: 3,
            photo: './gallery/photo_2024-08-28_19-25-49.jpg'
        },
        {
            id: 4,
            photo: './gallery/photo_2024-08-28_19-25-50.jpg'
        },
        {
            id: 5,
            photo: './gallery/photo_2024-08-28_19-25-52.jpg'
        },
        {
            id: 6,
            photo: './gallery/photo_2024-08-28_19-25-53.jpg'
        },
        {
            id: 7,
            photo: './gallery/photo_2024-08-28_19-25-54.jpg'
        },
        {
            id: 8,
            photo: './gallery/photo_2024-08-28_19-25-55 (2).jpg'
        },
        {
            id: 9,
            photo: './gallery/photo_2024-08-28_19-25-55.jpg'
        },
        {
            id: 10,
            photo: './gallery/photo_2024-08-28_19-25-56.jpg'
        },
        {
            id: 11,
            photo: './gallery/photo_2024-08-28_19-25-57.jpg'
        },
        {
            id: 12,
            photo: './gallery/photo_2024-08-28_19-25-58.jpg'
        },
    ]

  return (
    <div className={`${styles.galleryContainer}`} id='gallery'>
        <h2 className={mainStyles.blockTitle}>Наши работы</h2>
        <Swiper
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            speed={500}
            key={'gallery'}
            pagination
            modules={[ Navigation, A11y, Autoplay ]}
            slidesPerView={'auto'}
            navigation
            style={{userSelect: 'none'}}
            spaceBetween={24}
            loop
        >
            <div className={styles.galleryContent}>
                {
                    photos.map((el) => {
                        return <SwiperSlide key={el.id} className={styles.gallerySlide}>
                            <Image src={el.photo} className={styles.galleryImage} loading='lazy'/>
                        </SwiperSlide>
                    })
                }
            </div>
        </Swiper>
        <div className={styles.wrapper}></div>
    </div>
  )
}
